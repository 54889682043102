import BBBWidget from '../BBBWidget'

const LogosData = {
  bbb: {
    id: 'bbb-logo',
    alt: 'A+ rating for Freedom Debt Relief LLC BBB Business Review',
    component: BBBWidget,
  },
  best: {
    id: 'best-company-logo',
    alt: 'Freedom Debt Relief 4.7 average rating on over 15,000 reviews on Best Company review site',
    widget: {
      nofollow:
        '<a href="https://bestcompany.com/debt-relief/company/freedom-debt-relief" target="_blank" rel="nofollow noreferrer"><img src="/next-assets/footer/best-company.svg" alt="Freedom Debt Relief 4.7 average rating on over 15,000 reviews on Best Company review site" loading="lazy" width="112" height="47" style="margin: auto" /></a>',
      follow:
        '<a href="https://bestcompany.com/debt-relief/company/freedom-debt-relief" target="_blank" rel="noreferrer"><img src="/next-assets/footer/best-company.svg" alt="Freedom Debt Relief 4.7 average rating on over 15,000 reviews on Best Company review site" loading="lazy" width="112" height="47" style="margin: auto" /></a>',
    },
  },
  consumer: {
    id: 'consumer-affairs-logo',
    img: '/next-assets/footer/consumer-affairs.png',
    url: 'https://www.consumeraffairs.com/debt_counsel/freedom_debt_relief.html',
    alt: 'Over 2,400 Freedom Debt Relief reviews on Consumer Affairs with an average 4.5 rating',
  },
  aadr: {
    id: 'aadr-logo',
    img: '/next-assets/aadr-logo.png',
    alt: 'American Association for Debt Resolution',
    url: 'https://aa4dr.org/',
  },
  iapda: {
    id: 'iapda-logo',
    img: '/next-assets/footer/iapda-logo.webp',
    alt: 'IAPDA certified',
    url: null,
  },
  fhn: {
    id: 'fhn-logo',
    img: '/next-assets/footer/fhn-logo.webp',
    alt: 'Financial Health Network Member',
    url: null,
  },
}

export const LogosGroup = {
  desktopGroup: [
    LogosData.consumer,
    LogosData.bbb,
    LogosData.best,
    LogosData.aadr,
    LogosData.iapda,
    LogosData.fhn,
  ],
  mobileGroup: {
    groupOne: [LogosData.consumer, LogosData.bbb, LogosData.best],
    groupTwo: [LogosData.aadr, LogosData.iapda, LogosData.fhn],
  },
}
